import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Layout from '../layout/main';
import SEO from '../components/seo';

import Fade from 'react-reveal/Fade';

const Feature = () => {
	const data = useStaticQuery(POST_FEED_QUERY);
	return (
		<div id="feature">
			<div className="container">
				{data.allWordpressPost.edges.map((edge) => (
					<Link to={edge.node.slug}>
						<div className="post">
							<div className="post__thumbnail">
								<Fade>
									<Image
										sizes={edge.node.acf.thumbnail.localFile.childImageSharp.sizes}
										alt={edge.node.alt_text}
									/>
								</Fade>
							</div>
							<div className="post__details">
								<Fade>
									<h2 className="shout">{edge.node.title}</h2>
									<div className="talk" dangerouslySetInnerHTML={{ __html: edge.node.excerpt }} />
									<button className="btn btn__accent">Read More</button>
								</Fade>
							</div>
						</div>
					</Link>
				))}
			</div>
		</div>
	);
};

export default Feature;

const POST_FEED_QUERY = graphql`
	{
		allWordpressPost(limit: 1, sort: { order: DESC, fields: [date] }) {
			edges {
				node {
					title
					content
					excerpt
					date(formatString: "MMMM DD, YYYY")
					author {
						name
					}
					categories {
						name
					}
					slug
					acf {
						thumbnail {
							alt_text
							localFile {
								childImageSharp {
									sizes(maxWidth: 1200) {
										...GatsbyImageSharpSizes
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
